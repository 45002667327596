import { MSG_ERR } from 'src/consts/messages';
import { apiClient } from 'src/api/apiClient';
import { withDefaultHandlers } from 'src/api/helpers/withDefaultHandlers';
import {Price, PriceData} from 'src/types/users';

const BASE_PATH_ADMIN = '/admin';

export const apiGetPriceList = (data: { organisation_id: number, doctor_id: number, type_id: string }) => withDefaultHandlers(
    apiClient.post<{ list: (Price & { id: number })[], total: number }>(`${BASE_PATH_ADMIN}/prices/list`, {
        params: {
            filters: [
                {
                    field: 'organisation_id',
                    operation: '=',
                    value: data.organisation_id,
                },
                {
                    field: 'doctor_id',
                    operation: '=',
                    value: data.doctor_id
                },
                {
                    field: 'type_id',
                    operation: 'in',
                    value: data.type_id.split(',')
                }
            ]
        }
    }),
    MSG_ERR.PRICE_GET
);

export const apiUpdatePrice = (id: number, price: number) => withDefaultHandlers(
    apiClient.put<any>(`${BASE_PATH_ADMIN}/prices/${id}`, { price }),
    MSG_ERR.PRICE_UPDATE
);

export const apiCreatePrice = (data: PriceData) => withDefaultHandlers(
    apiClient.post<any>(`${BASE_PATH_ADMIN}/prices/batch-add`, data),
    MSG_ERR.PRICE_UPDATE
);