import { Moment } from 'moment';
import { Roles } from 'src/types/common';
import { OrgDTO } from 'src/types/organisation';

export enum Sex {
    Male = 'male',
    Female = 'female'
}

export interface User {
    id: number;
    name: string;
    surname: string;
    middle_name: string;
    phone: string;
    role: Roles;
    status: number | string;
}

export interface Doctor extends User {
    description?: string;
    employment_place?: string;
    employment_address?: string;
    speciality?: string;
    category?: string;
    experience?: string;
    doctor_id?: number;
    organisation_id?: number;
    organisation?: OrgDTO;
}

export interface Patient extends User {
    sex?: Sex;
    birthday?: string | Moment;
}

export type Price = {
    organisation_id: number,
    doctor_id: number,
    type_id: number,
    price: number
};

export type PriceData = Price[];
